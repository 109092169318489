import { Web3Provider } from '@ethersproject/providers';

export const DATA_WIDGET_VALUE = 'm-connect';
// events
export const AUTHENTICATED = 'm-authenticated';
export const UNAUTHENTICATED = 'm-unauthenticated';
export const REAUTHENTICATE = 'm-reauthenticate';
export const MANIFOLD_LOAD_REQUEST = 'm-refresh-widgets';

export const ETHEREUM_NETWORK_COLORS: Record<number, string> = {
  1: '#59B3AE',
  3: '#EC5A8C',
  4: '#EFC45B',
  5: '#4D99EB',
  42: '#886BF6',
  137: '#7B4ADD',
  10: '#fd0826',
  8453: '#2151f5',
  11155111: '#4D99EB'
};

export const ETHEREUM_NETWORK_NAMES: Record<number, string> = {
  1: 'MAINNET',
  3: 'ROPSTEN',
  4: 'RINKEBY',
  5: 'GOERLI',
  42: 'KOVAN',
  137: 'MATIC',
  10: 'OPTIMISM',
  8453: 'BASE',
  11155111: 'SEPOLIA'
};

export interface InjectedProvider {
  isCoinbaseWallet?: boolean;
  isBraveWallet?: boolean;
  isBitski?: boolean;
  isLedgerConnect?: boolean;
  isMetaMask?: boolean;
  overrideIsMetaMask?: boolean;
  providers?: InjectedProvider[];
  disconnect: () => void;
}

export interface AbstractProvider extends Web3Provider {
  provider: InjectedProvider;
}

interface ManifoldRefreshDetail {
  widget: string; // the name of the widget script
}

export type MManifoldRefreshWidgetsEvent = CustomEvent<ManifoldRefreshDetail>;

export const ALLOWED_WALLET_CONNECT_WALLET_IDS = [
  '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369', // Rainbow
  '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // Trust
  'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // metamask
  'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa', // coinbase
  '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927' // Ledger Live
];
